import axios from "axios";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { getProductsByFilter } from "@storefront/core/data-resolver/products";
const state = () => ({
  serie: [],
  backLink: [],
});

const actions = {
  /**
   * load Blog by Identifier
   * @param {json} identifier
   */
  async loadSerie({ commit }, { serie_id }) {
    const filter = 'serie:{eq:"' + serie_id + '"}';
    const page = await getProductsByFilter(filter).catch((e) => {
      Logger.error("loadSerie", "productSerie", e)();
      throw e;
    });
    if (page != null) {
      commit("setSerie", page.items);
    }
  },

  async getBlogsBySearchPreview(_, { filter, signal }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    const query =
      " { blogPosts(filter:" +
      ' { title: { like: "%' +
      filter +
      '%" } } ' +
      "pageSize: 5)" +
      "{ items { content title } total_count total_pages }}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      signal: signal,
    }).catch((e) => {
      Logger.error("getBlogsBySearchPreview", "productSerie", e)();
      throw e;
    });
    return retval.data.data.blogPosts;
  },

  async getCategoriesBySearchPreview(_, { filter, signal }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    Logger.debug('getCategoriesBySearchPreview', 'index', filter)();
    const query =
      "{categories(search:" +
      JSON.stringify(
        filter
          .replace(/\s+/g, " ")
          .trim()
          ?.toLowerCase() || ''
      ) +
      "){items{name url_key url_path level path image product_count children{name image description}}}}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      signal: signal,
    }).catch((e) => {
      Logger.error("getCategoriesBySearchPreview", "productSerie", e)();
      throw e;
    });
    return retval.data.data.categories;
  },
  async loadBackLink({ commit }, { sku }) {

    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    const query =
      "{products(filter: {sku:{eq:\"" + sku + "\"}}) {items{url_key image{x_small}}}}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadBackLink", "productSerie", e)();
      throw e;
    });
    const items = retval.data?.data?.products?.items[0] || [];
    commit("setBackLink", { items, sku });
  }
};

const mutations = {
  /**
   * set list page
   * @param {object} state
   * @param {object} payload
   */
  setSerie(state, payload) {
    state.serie = payload;
  },
  setBackLink(state, items) {
    state.backLink = [...state.backLink, items];
  },

  resetBackLink(state, removedSkuData) {
    if (removedSkuData) {
      state.backLink = state.backLink.filter(
        (linkItem) => linkItem.sku !== removedSkuData.sku
      );
    } else {
      state.backLink = [];
    }
  }
};

const getters = {
  getSerie: (state) => state.serie,
  getBackLink: (state) => state.backLink,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
