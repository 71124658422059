const state = () => ({
    isLoading: false,
})

const actions = {
    setLoading({ commit }, loading) {
        commit('setLoading', loading);
    }
}

const mutations = {
    setLoading(state, loading) {
        state.isLoading = loading;
    }
}

const getters = {
    isLoading: (state) => state.isLoading,
}

export default {
    state,
    actions,
    mutations,
    getters
};
