import { loadContentBlocks } from "@storefront/core/data-resolver/cms";
import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  items: [],
  checkoutStatus: null,
});

const actions = {
  async single({ getters, commit }, { key = "identifier", value = "" }) {
    const cmsBlock = getters.findCmsBlocks({ key, value });

    if (!cmsBlock) {
      const blockResponse = await loadContentBlocks([value]).catch((e) => {
        Logger.error(
          "loadContentBlocks",
          "cms block store actions single",
          e
        )();
        throw e;
      });
      if (blockResponse.items.length > 0) {


        if (blockResponse.items[0].content.indexOf('rel') == -1) {

          /* blockResponse.items[0].content = blockResponse.items[0].content.replace(
             /<a href=/g,
             "<b-link to="
           );
           blockResponse.items[0].content = blockResponse.items[0].content.replace(
             /<a/g,
             "<b-link"
           );
           blockResponse.items[0].content = blockResponse.items[0].content.replace(
             /<\/a>/g,
             "</b-link>"
           );
           blockResponse.items[0].content = blockResponse.items[0].content.replace(
             / href=/g,
             " to="
           );*/
        }

        commit("setCmsBlock", blockResponse.items[0]);
        return blockResponse.items[0];
      }
    }
    return cmsBlock[0];
  },
  async multiple(
    { commit },
    {
      //key = "identifier",
      value = [],
    }
  ) {
    //const cmsBlock = getters.findCmsBlocks({ key, value });
    const blockResponse = await loadContentBlocks(value).catch((e) => {
      Logger.error(
        "loadContentBlocks",
        "cms block store actions multiple",
        e
      )();
      throw e;
    });

    if (blockResponse.items.length > 0) {

      blockResponse.items.forEach((element) => {
        if (element) {
          if (element.identifier == 'header_links' || element.identifier == 'footer_menu_information') {
            element.content = element.content?.replace(/komtnietvoor/g, "<b-link to=");
          }
          else {
            element.content = element.content?.replace(/<a href=/g, "<b-link to=");
            element.content = element.content?.replace(/<a/g, "<b-link");
            element.content = element.content?.replace(/<\/a>/g, "</b-link>");
            element.content = element.content?.replace(/ href="\//g, ' to="/');
            element.content = element.content?.replace(/<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g, '<a$1href="$2$3</a>');
          }

          commit("setCmsBlock", element);
        }
      });

      return blockResponse.items;
    }

    //return cmsBlock[0];
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  setCmsBlock(state, data) {
    if (typeof data == "object") {
      const record = state.items.findIndex(
        (c) => c.identifier === data.identifier
      );
      if (record != -1) {
        state.items.splice(record, 1);
      }
      state.items.push(data);
    }
  },
};

const getters = {
  getCmsBlockByIdentifier: (state) => (identifier) =>
    state.items.find(
      (item) => typeof item === "object" && item.identifier === identifier
    ),
  findCmsBlocks: (state) => ({ key, value }) =>
    state.items.filter((item) => item[key] === value),
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
